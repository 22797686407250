@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/ui/src/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}

.info-linear-gradient {
  background: rgb(97, 144, 228);
  background: linear-gradient(
    90deg,
    rgba(97, 144, 228, 0.5) 15%,
    rgba(214, 226, 255, 0.2) 100%
  );
}

.successful-radial-gradient {
  background: rgb(39, 178, 118);
  background: radial-gradient(
    circle at center 30%,
    rgba(39, 178, 118, 0.2) 0%,
    rgba(244, 242, 246, 0) 40%
  );
}

.error-radial-gradient {
  background: rgb(255, 84, 73);
  background: radial-gradient(
    circle at center 30%,
    rgba(255, 84, 73, 0.2) 0%,
    rgba(244, 242, 246, 0) 40%
  );
}

.info-radial-gradient {
  background: rgb(0, 72, 153);
  background: radial-gradient(
    circle at center 30%,
    rgba(0, 72, 153, 0.2) 0%,
    rgba(244, 242, 246, 0) 40%
  );
}
